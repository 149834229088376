import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Input,
  Select,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import { getUsersApi, updateUserApi, deleteUserApi } from "networks"; // Import your API functions

const CustomerManagement = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]); // For filtered results
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // For search bar
  const [statusFilter, setStatusFilter] = useState(""); // For status dropdown filter

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await getUsersApi(); // Fetch users using the API
      setUserDetails(response.data.data); // Assuming `response.data.data` contains the user details
      setFilteredUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to format the user registration date
  const formatUserDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  // Filter users based on search term and status filter
  useEffect(() => {
    let filtered = userDetails;

    // Filter by search term (user name or phone number)
    if (searchTerm) {
      filtered = filtered.filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.phone_number.includes(searchTerm)
      );
    }

    // Filter by status
    if (statusFilter) {
      filtered = filtered.filter((user) => user.status === statusFilter);
    }

    setFilteredUsers(filtered);
  }, [searchTerm, statusFilter, userDetails]); // Runs every time searchTerm, statusFilter, or userDetails changes

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle status dropdown change
  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleEditUser = async (user) => {
    console.log("Edit User:", user);
    try {
      // Handle edit user logic here
      const updatedUser = { ...user, status: "Active" }; // Example of changing the status
      await updateUserApi(updatedUser); // Call API to update user
      fetchUsers(); // Refresh the user list
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleDeleteUser = async (user) => {
    console.log("Delete User:", user);
    try {
      await deleteUserApi(user.user_id); // Call API to delete user by user id
      fetchUsers(); // Refresh the user list
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY={{ sm: "scroll", lg: "hidden" }}>
      <Flex flexDirection="column">
        <Flex mt="45px" mb="20px" justifyContent="space-between" align={{ base: "start", md: "center" }}>
          <Text fontSize="2xl" ms="24px" fontWeight="700">
            User Management
          </Text>
        </Flex>

        <Flex justifyContent={"center"} mb={5}>
          <Input
            placeholder="Search by User Name or Phone"
            value={searchTerm}
            onChange={handleSearchChange}
            width="40%"
            mr={3}
            backgroundColor={"white"}
          />
          <Select placeholder="Filter by Status" onChange={handleStatusFilterChange} width="20%" backgroundColor={"white"}>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="pending">Pending</option>
          </Select>
        </Flex>

        <Flex justifyContent={"center"} mt={30}>
          <Card width={"97%"} borderRadius={40} bg={"#ededed"}>
            <Box p={5}>
              {loading ? (
                <Flex justify="center" align="center" height="100vh">
                  <Spinner size="xl" />
                </Flex>
              ) : (
                // Add a safety check to ensure filteredUsers is an array
                filteredUsers && filteredUsers.length > 0 ? (
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>User ID</Th>
                        <Th>User Name</Th>
                        <Th>Phone Number</Th>
                        <Th>Total Wallet Amount</Th>
                        <Th>Reffered By</Th>
                        <Th>Email</Th>
                        <Th>Registration Date</Th>
                        <Th>Status</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredUsers.map((user, index) => (
                        <Tr key={index}>
                          <Td>{user.user_id}</Td>
                          <Td>{user.name}</Td>
                          <Td>{user.phone_number}</Td>
                          <Td>{user.wallet_amount}</Td>
                          <Td>{user.referral_id}</Td>
                          <Td>{user.email}</Td>
                          <Td>{formatUserDate(user.created_at)}</Td>
                          <Td>{user.status}</Td>
                          <Td>
                            <Flex gap="1px">
                              <IconButton
                                icon={<MdVisibility />}
                                aria-label="View User"
                                onClick={() => console.log("View user", user)}
                              />
                              <IconButton
                                icon={<MdEdit />}
                                onClick={() => handleEditUser(user)}
                                aria-label="Edit User"
                              />
                              <IconButton
                                icon={<MdDelete />}
                                onClick={() => handleDeleteUser(user)}
                                aria-label="Delete User"
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Text>No users available</Text>
                )
              )}
            </Box>
          </Card>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CustomerManagement;
