import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Select,
} from '@chakra-ui/react';

const EditBanner = ({ isOpen, onClose, updateBannerProp, updateBanner }) => {
  const [banner, setBanner] = useState({
    id: '',
    name: '',
    description: '',
    position: '',
    status: '',
    city: '',
  });

  const toast = useToast();

  useEffect(() => {
    console.log(updateBannerProp)
    if (updateBannerProp) {
      setBanner({
        id: updateBannerProp.id || '',
        name: updateBannerProp.name || '',
        description: updateBannerProp.description || '',
        position: updateBannerProp.position || '',
        status: updateBannerProp.status || '',
        city: updateBannerProp.city || '',
      });
    }
  }, [updateBannerProp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBanner({ ...banner, [name]: value });
  };

  const handleSubmit = () => {
    if (banner.name && banner.description && banner.position && banner.status && banner.city) {
      const formData = {
        id:banner.id,
        name: banner.name,
        description: banner.description,
        position: banner.position,
        status: banner.status,
        city: banner.city,
      };
      updateBanner(formData);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(null)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Banner</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Banner Name */}
          <FormControl id="name" mb={3}>
            <FormLabel>Banner Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={banner.name}
              onChange={handleInputChange}
              placeholder="Enter banner name"
            />
          </FormControl>

          {/* Description */}
          <FormControl id="description" mb={3}>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              name="description"
              value={banner.description}
              onChange={handleInputChange}
              placeholder="Enter banner description"
            />
          </FormControl>

          {/* Position as Dropdown */}
          <FormControl id="position" mb={3}>
            <FormLabel>Position</FormLabel>
            <Select
              name="position"
              value={banner.position}
              onChange={handleInputChange}
              placeholder="Select position"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </FormControl>

          {/* City Dropdown */}
          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={banner.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          {/* Status */}
          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={banner.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={() => onClose(null)}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditBanner;
