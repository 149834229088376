import React, { useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Divider,
  Flex,
  useBreakpointValue,
  Image,
} from '@chakra-ui/react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from 'assets/img/logo3.png';

const OrderInvoiceView = ({ isOpen, onClose, orderData }) => {
  const invoiceRef = useRef();
  const modalSize = useBreakpointValue({ base: 'full', md: '6xl' });
  const invoiceScale = useBreakpointValue({ base: 0.75, md: 1 });
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const buttonFontSize = useBreakpointValue({ base: 11, md: 16 });

  if (!orderData) return null;

  // Calculate the total discounted price
  const totalDiscountedPrice = orderData.products.reduce(
    (acc, product) => acc + product.discounted_price * product.quantity,
    0
  );

  const handleDownloadInvoice = async () => {
    const invoice = invoiceRef.current;
    const canvas = await html2canvas(invoice, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('invoice.pdf');
  };

  const handlePrintInvoice = async () => {
    const invoice = invoiceRef.current;
    const canvas = await html2canvas(invoice, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write('<html><head><title>Invoice</title>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
      body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        background-color: white;
      }
      .invoice-box {
        width: 210mm;
        height: 297mm;
        padding: 20mm;
        margin: auto;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        background-color: white;
        box-sizing: border-box;
      }
      .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
      }
      .invoice-box table td {
        padding: 5px;
        vertical-align: top;
      }
      .invoice-box table tr td:nth-child(2) {
        text-align: right;
      }
      .invoice-box table tr.top table td {
        padding-bottom: 20px;
      }
      .invoice-box table tr.information table td {
        padding-bottom: 40px;
      }
      .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
      }
      .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
      }
      .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
      }
    `);
    printWindow.document.write('</style></head><body>');
    printWindow.document.write('<div class="invoice-box">');
    printWindow.document.write(`<img src="${imgData}" style="width: 100%;"/>`);
    printWindow.document.write('</div></body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invoice</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH={{ base: '70vh', md: '80vh' }} overflowY="auto" bg="white">
          <Box
            ref={invoiceRef}
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            className="invoice-box"
            style={{
              width: '210mm',
              height: '297mm',
              backgroundColor: 'white',
              boxSizing: 'border-box',
              margin: '0 auto',
              transform: `scale(${invoiceScale})`,
              transformOrigin: 'top left',
            }}
          >
            {/* Header with Logo and Company Info */}
            <Flex justify="space-between" mb={4}>
              <Image
                src={logo} // Replace with actual path to logo or dynamic source
                alt="Company Logo"
                width="160px"
                height="90px"
                objectFit="contain"
              />
              <Box textAlign="center" flex="1" ml={4}>
                <Text fontSize="xl">
                  <strong>Kalpasiri Farms And Foods Pvt Ltd</strong>
                </Text>
                <Text>156/174 & #94/139, SH 1,
Hebbal, Mandya,
Karnataka, 571404</Text>
              </Box>
            </Flex>
            <Divider my={4} />

            {/* Customer and Invoice Info */}
            <Flex justify="space-between" mb={4}>
              <Box textAlign="left">
                <Text fontSize={20}>
                  <strong>{orderData.name}</strong>
                </Text>
                <Text>{orderData.email}</Text>
                <Text>{orderData.delivery_address}</Text>
                <Text>{orderData.phone_number}</Text>
              </Box>
              <Box textAlign="right">
                <Text>
                  <strong>Invoice ID:</strong> {orderData.order_id}
                </Text>
                <Text>
                  <strong>Date:</strong> {new Date(orderData.order_date).toLocaleDateString()}
                </Text>
                <Text>
                  <strong>Payment ID:</strong> {orderData.payment_id}
                </Text>
                <Text>
                  <strong>Status:</strong> {orderData.status}
                </Text>
                <Text>
                  <strong>Delivery Timeslot:</strong> {orderData.delivery_time_slot}
                </Text>
              </Box>
            </Flex>
            <Divider my={4} />

            {/* Product Table */}
            <Box overflowX="auto">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Product</Th>
                    <Th>Quantity</Th>
                    <Th>Price (₹)</Th>
                    <Th>Discounted Price (₹)</Th>
                    <Th>Total (₹)</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orderData.products.map((product, index) => {
                    const totalAmount = product.discounted_price * product.quantity;
                    return (
                      <Tr key={index}>
                        <Td>{product.product_name}</Td>
                        <Td>{product.quantity}</Td>
                        <Td>{`₹${product.price}`}</Td>
                        <Td>{`₹${product.discounted_price}`}</Td>
                        <Td>{`₹${totalAmount.toFixed(2)}`}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
            <Divider my={4} />

            {/* Summary */}
            <Flex justify="flex-end">
              <Box textAlign="right">
                <Text fontSize="lg" fontWeight="bold">
                  <strong>Total:</strong> ₹{orderData.total_amount}
                </Text>
              </Box>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleDownloadInvoice} fontSize={buttonFontSize} size={buttonSize}>
            Download PDF
          </Button>
          <Button colorScheme="green" onClick={handlePrintInvoice} ml={3} fontSize={buttonFontSize} size={buttonSize}>
            Print Invoice
          </Button>
          <Button colorScheme="gray" onClick={onClose} ml={3} fontSize={buttonFontSize} size={buttonSize}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderInvoiceView;
