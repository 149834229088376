import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Image,
  Grid,
} from '@chakra-ui/react';
import { getImageApi } from 'networks';

const ViewProduct = ({ isOpen, onClose, product }) => {
    console.log(product)
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Product Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Display product details */}
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Text fontWeight="bold">Product Code:</Text>
            <Text>{product.product_code}</Text>

            <Text fontWeight="bold">Name:</Text>
            <Text>{product.name}</Text>

            <Text fontWeight="bold">Category:</Text>
            <Text>{product.category}</Text>

            <Text fontWeight="bold">Price:</Text>
            <Text>{product.price}</Text>

            <Text fontWeight="bold">City:</Text>
            <Text>{product.city}</Text>

            <Text fontWeight="bold">CGST:</Text>
            <Text>{product.cgst}</Text>

            <Text fontWeight="bold">SGST:</Text>
            <Text>{product.sgst}</Text>

            <Text fontWeight="bold">Discount:</Text>
            <Text>{product.discount}</Text>

            <Text fontWeight="bold">Status:</Text>
            <Text>{product.status}</Text>

            <Text fontWeight="bold">Description:</Text>
            <Text>{product.description}</Text>

            <Text fontWeight="bold">Subscription:</Text>
            <Text>{product.subscription}</Text>
          </Grid>

          {/* Product Images */}
          <Text fontWeight="bold" mt={4}>Images:</Text>
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            {product.image_urls && product.image_urls.map((url, index) => (
                <>
              <Image
              src={getImageApi(url)}
              alt={`Category ${product.name}`}
              boxSize="50px"
              objectFit="cover"
              borderRadius="md" 
            />
            </>
            ))}
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewProduct;
