import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Input,
  Select,
  Button,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import {
  getSubscriptionsApi,
  updateSubscriptionApi,
  deleteSubscriptionApi,
} from "networks"; // Import your API functions

const SubscriptionManagement = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    setLoading(true);
    try {
      const response = await getSubscriptionsApi(); // Fetch subscriptions from API
      setSubscriptions(response.data.data);
      setFilteredSubscriptions(response.data.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Format the subscription creation date
  const formatSubscriptionDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  // Filter subscriptions based on search term and status filter
  useEffect(() => {
    let filtered = subscriptions;

    if (searchTerm) {
      filtered = filtered.filter(
        (subscription) =>
          subscription.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          subscription.phone_number.includes(searchTerm)
      );
    }

    if (statusFilter) {
      filtered = filtered.filter((subscription) => subscription.status === statusFilter);
    }

    setFilteredSubscriptions(filtered);
  }, [searchTerm, statusFilter, subscriptions]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle status dropdown change
  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleEditSubscription = async (subscription) => {
    console.log("Edit Subscription:", subscription);
    try {
      const updatedSubscription = { ...subscription, status: "Active" }; // Example status update
      await updateSubscriptionApi(updatedSubscription);
      fetchSubscriptions();
    } catch (error) {
      console.error("Error updating subscription:", error);
    }
  };

  const handleDeleteSubscription = async (subscription) => {
    console.log("Delete Subscription:", subscription);
    try {
      await deleteSubscriptionApi(subscription.subscription_id);
      fetchSubscriptions();
    } catch (error) {
      console.error("Error deleting subscription:", error);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY={{ sm: "scroll", lg: "hidden" }}>
      <Flex flexDirection="column">
        <Flex mt="45px" mb="20px" justifyContent="space-between" align={{ base: "start", md: "center" }}>
          <Text fontSize="2xl" ms="24px" fontWeight="700">
            Subscription Management
          </Text>
        </Flex>

        <Flex justifyContent={"center"} mb={5}>
          <Input
            placeholder="Search by User Name or Phone"
            value={searchTerm}
            onChange={handleSearchChange}
            width="40%"
            mr={3}
            backgroundColor={"white"}
          />
          <Select placeholder="Filter by Status" onChange={handleStatusFilterChange} width="20%" backgroundColor={"white"}>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="pending">Pending</option>
          </Select>
        </Flex>

        <Flex justifyContent={"center"} mt={30}>
          <Card width={"97%"} borderRadius={40} bg={"#ededed"}>
            <Box p={5}>
              {loading ? (
                <Flex justify="center" align="center" height="100vh">
                  <Spinner size="xl" />
                </Flex>
              ) : filteredSubscriptions && filteredSubscriptions.length > 0 ? (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Subscription ID</Th>
                      <Th>User Name</Th>
                      <Th>Phone Number</Th>
                      <Th>Product Name</Th>
                      <Th>Subscription Type</Th>
                      <Th>Start Date</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredSubscriptions.map((subscription, index) => (
                      <Tr key={index}>
                        <Td>{subscription.subscription_id}</Td>
                        <Td>{subscription.user_name}</Td>
                        <Td>{subscription.phone_number}</Td>
                        <Td>{subscription.product_name}</Td>
                        <Td>{subscription.subscription_type}</Td>
                        <Td>{formatSubscriptionDate(subscription.created_at)}</Td>
                        <Td>{subscription.status}</Td>
                        <Td>
                          <Flex gap="1px">
                            <IconButton
                              icon={<MdVisibility />}
                              aria-label="View Subscription"
                              onClick={() => console.log("View subscription", subscription)}
                            />
                            <IconButton
                              icon={<MdEdit />}
                              onClick={() => handleEditSubscription(subscription)}
                              aria-label="Edit Subscription"
                            />
                            <IconButton
                              icon={<MdDelete />}
                              onClick={() => handleDeleteSubscription(subscription)}
                              aria-label="Delete Subscription"
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Text>No subscriptions available</Text>
              )}
            </Box>
          </Card>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SubscriptionManagement;
