import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  useDisclosure,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
  Image,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete, MdVisibility } from "react-icons/md"; // Added MdVisibility for the view button
import { SearchIcon } from "@chakra-ui/icons";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";
import ViewProduct from "./ViewProduct"; // Import the ViewProduct component
import { getProductsApi, addProductApi, updateProductApi, deleteProductApi } from "networks";
import { getImageApi } from "networks";

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editProductData, setEditProductData] = useState(null);
  const [deleteProductData, setDeleteProductData] = useState(null);
  const [viewProductData, setViewProductData] = useState(null); // State for viewing a product
  const [searchTerm, setSearchTerm] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const addProductModal = useDisclosure();
  const toast = useToast();
  const viewProductModal = useDisclosure(); // Disclosure for the ViewProduct modal

  useEffect(() => {
    fetchProductsData();
  }, []);

  const fetchProductsData = async () => {
    setLoading(true);
    try {
      const response = await getProductsApi(); 
      setProducts(response.data.data);
    } catch (error) {
      toast({
        title: "Error fetching products.",
        description: "An error occurred while fetching products.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddProduct = async (newProduct) => {
    try {
      await addProductApi(newProduct);
      fetchProductsData();
      toast({
        title: "Product added.",
        description: "The product has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      addProductModal.onClose();
    } catch (error) {
      toast({
        title: "Error adding product.",
        description: "An error occurred while adding the product.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateProduct = async (updatedProduct) => {
    try {
      await updateProductApi(updatedProduct);
      fetchProductsData();
      toast({
        title: "Product updated.",
        description: "The product has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEditProductData(null);
    } catch (error) {
      toast({
        title: "Error updating product.",
        description: "An error occurred while updating the product.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await deleteProductApi(id);
      fetchProductsData();
      toast({
        title: "Product deleted.",
        description: "The product has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setDeleteProductData(null);
    } catch (error) {
      toast({
        title: "Error deleting product.",
        description: "An error occurred while deleting the product.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditProduct = (product) => {
    setEditProductData(product);
  };

  const handleDeleteProductModal = (product) => {
    setDeleteProductData(product);
  };

  const handleViewProduct = (product) => {
    setViewProductData(product); // Set the product to be viewed
    viewProductModal.onOpen(); // Open the view product modal
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.productCode.toLowerCase().includes(searchTerm.toLowerCase()) || 
    product.status.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Product Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            onClick={addProductModal.onOpen}
          >
            Add Product
          </Button>
        </Flex>

        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by name, category, city, status, or product code..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => setSearchTerm('')}
                variant="ghost"
                size="md"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>

        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Name</Th>
                      <Th>Image</Th>
                      <Th>Weight</Th>
                      <Th>Product Code</Th>
                      <Th>Category</Th>
                      <Th>Price</Th>
                      <Th>Subscription</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredProducts.map((product) => (
                      <Tr key={product.id}>
                        <Td>{product.id}</Td>
                        <Td>{product.name}</Td>
                        <Td>                         
                            <Image
                            src={getImageApi(product.image_urls[0])}
                            alt={`Category ${product.name}`}
                            boxSize="50px"
                            ob jectFit="cover"
                          /></Td>
                        <Td>{product.weight} {product.weight_unit}</Td>
                        <Td>{product.product_code}</Td>
                        <Td>{product.category_name}</Td>
                        <Td>{product.price}</Td>
                        <Td>{product.subscription}</Td>
                        <Td color={product.status === "Active" ? "green.500" : "red.500"}>{product.status}</Td>
                        <Td>
                          <IconButton
                            icon={<MdVisibility />} // View product button
                            onClick={() => handleViewProduct(product)}
                            aria-label="View Product"
                            mr={2}
                            colorScheme="blue"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => handleEditProduct(product)}
                            aria-label="Edit Product"
                            mr={2}
                            colorScheme="teal"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => handleDeleteProductModal(product)}
                            aria-label="Delete Product"
                            colorScheme="red"
                            size="sm"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>
      </Flex>

      {/* AddProduct modal */}
      <AddProduct
        isOpen={addProductModal.isOpen}
        onClose={addProductModal.onClose}
        addNewProduct={handleAddProduct}
      />

      {/* EditProduct modal */}
      {editProductData && (
        <EditProduct
          isOpen={!!editProductData}
          onClose={() => setEditProductData(null)}
          updateProductProp={editProductData}
          updateProduct={handleUpdateProduct}
        />
      )}

      {/* ViewProduct modal */}
      {viewProductData && (
        <ViewProduct
          isOpen={viewProductModal.isOpen}
          onClose={viewProductModal.onClose}
          product={viewProductData} // Pass the product to the view modal
        />
      )}

      {/* DeleteProduct modal */}
      {deleteProductData && (
        <DeleteProduct
          isOpen={!!deleteProductData}
          onClose={() => setDeleteProductData(null)}
          deleteProductProp={deleteProductData}
          deleteProduct={handleDeleteProduct}
        />
      )}
    </Box>
  );
};

export default ProductManagement;
