import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  useDisclosure,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
  Image,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import DeleteCategory from "./DeleteCategory";
import { getCategoriesApi, addCategoryApi, updateCategoryApi, deleteCategoryApi, getImageApi } from "networks";

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editCategoryData, setEditCategoryData] = useState(null);
  const [deleteCategoryData, setDeleteCategoryData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const addCategoryModal = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    fetchCategoriesData();
  }, []);

  // Fetch all categories from the server
  const fetchCategoriesData = async () => {
    setLoading(true);
    try {
      const response = await getCategoriesApi();  // Call API
      setCategories(response.data.data);  // Assuming API returns data in response.data
    } catch (error) {
      toast({
        title: "Error fetching categories.",
        description: "An error occurred while fetching categories.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Add a new category
  const handleAddCategory = async (newCategory) => {
    try {
      await addCategoryApi(newCategory);  // Call API to add category
      fetchCategoriesData();
      toast({
        title: "Category added.",
        description: "The category has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      addCategoryModal.onClose();
    } catch (error) {
      toast({
        title: "Error adding category.",
        description: "An error occurred while adding the category.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Update an existing category
  const handleUpdateCategory = async (updatedCategory) => {
    try {
      await updateCategoryApi(updatedCategory);  
      fetchCategoriesData();
      toast({
        title: "Category updated.",
        description: "The category has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEditCategoryData(null);
    } catch (error) {
      toast({
        title: "Error updating category.",
        description: "An error occurred while updating the category.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Delete a category
  const handleDeleteCategory = async (id) => {
    try {
      await deleteCategoryApi(id); 
      fetchCategoriesData();
      toast({
        title: "Category deleted.",
        description: "The category has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setDeleteCategoryData(null);
    } catch (error) {
      toast({
        title: "Error deleting category.",
        description: "An error occurred while deleting the category.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditCategory = (category) => {
    setEditCategoryData(category);
  };

  const handleDeleteCategoryModal = (category) => {
    setDeleteCategoryData(category);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    category.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
    category.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Category Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            onClick={addCategoryModal.onOpen}
          >
            Add Category
          </Button>
        </Flex>

        {/* Search Bar */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by name, city, or status..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => setSearchTerm('')}
                variant="ghost"
                size="md"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Category Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Name</Th>
                      <Th>Image</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredCategories.map((category) => (
                      <Tr key={category.id}>
                        <Td>{category.id}</Td>
                        <Td>{category.name}</Td>
                        <Td>
                         <Image
                            src={getImageApi(category.image_url)}
                            alt={`Category ${category.name}`}
                            boxSize="50px"
                            ob jectFit="cover"
                          />
                        </Td>
                        <Td color={category.status === "Active" ? "green.500" : "red.500"}>
                          {category.status}
                        </Td>
                        <Td>
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => handleEditCategory(category)}
                            aria-label="Edit Category"
                            mr={2}
                            colorScheme="teal"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => handleDeleteCategoryModal(category)}
                            aria-label="Delete Category"
                            colorScheme="red"
                            size="sm"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>
      </Flex>

      {/* AddCategory modal */}
      <AddCategory
        isOpen={addCategoryModal.isOpen}
        onClose={addCategoryModal.onClose}
        addNewCategory={handleAddCategory}
      />

      {/* EditCategory modal */}
      {editCategoryData && (
        <EditCategory
          isOpen={!!editCategoryData}
          onClose={() => setEditCategoryData(null)}
          updateCategoryProp={editCategoryData}
          updateCategory={handleUpdateCategory}
        />
      )}

      {/* DeleteCategory modal */}
      {deleteCategoryData && (
        <DeleteCategory
          isOpen={!!deleteCategoryData}
          onClose={() => setDeleteCategoryData(null)}
          deleteCategoryProp={deleteCategoryData}
          deleteCategory={handleDeleteCategory}
        />
      )}
    </Box>
  );
};

export default CategoryManagement;
